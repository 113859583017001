import React from 'react';
import PropTypes from 'prop-types';

import { Footer as SiteFooter, TextBlock } from '@lxlabs/change-ad-components';
import Logos from '../../assets/image/sanofi-regeneron-logo.svg';

const defaultDate = 'v1.0 July 2021';

const parseVeevaCode = (value) => (value.length !== 15 ? value : `${value} ${defaultDate}`);

const Footer = ({ veevaCode }) => (
  <SiteFooter
    siteLinks={[
      { label: 'Understanding AD', url: '/understanding-ad/' },
      { label: 'Managing AD', url: '/managing-ad/' },
      { label: 'Initiatives', url: '/initiatives/' },
      { label: 'Resources', url: '/resources/' },
    ]}
    legalLinks={
    [
      { label: 'Privacy Policy', url: '/privacy-policy/' },
      { label: 'Legal Notice', url: '/legal-notice/' },
      { label: 'Cookie Policy', url: '/cookie-policy/' },
    ]
  }
    logos={[
      { src: Logos, alt: 'Sanofi Regeneron', height: 40 },
    ]}
    legalText={(
      <TextBlock>
        &copy;2022 Sanofi &amp; Regeneron - All rights reserved.
        <br />
        Sanofi and Regeneron are registered trademarks.
        <br />
        Sanofi and Regeneron are responsible only for the content contained within this website.
        <br />
        {parseVeevaCode(veevaCode)}
      </TextBlock>
  )}
  />
);

Footer.propTypes = {
  veevaCode: PropTypes.string,
};

Footer.defaultProps = {
  veevaCode: 'MAT-GLB-2102347',
};

export default Footer;
