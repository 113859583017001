import React from 'react';
import Helmet from 'react-helmet';

const CookieBanner = () => (
  <Helmet>
    <script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      charset="UTF-8"
      data-domain-script={process.env.GATSBY_COOKIE_DOMAIN_SCRIPT}
    />
  </Helmet>
);

export default CookieBanner;
