import React from 'react';

import { Header as SiteHeader } from '@lxlabs/change-ad-components';
import ChangeADLogo from '../../assets/image/change-ad-logo.svg';
import FeatureImage from '../../assets/image/feature-image.png';
import FeatureImageMobile from '../../assets/image/feature-image-mobile.png';

const Header = () => (
  <SiteHeader
    logo={ChangeADLogo}
    navigationLinks={[
      {
        label: 'Understanding AD',
        url: '/understanding-ad/',
        header: {
          label: 'Understanding AD',
          url: '/understanding-ad/',
        },
        highlights: [
          {
            type: 'list',
            title: 'Learning Tools',
            links: [
              [
                { label: 'Glossary', url: '/resources/ad-glossary/' },
                { label: 'Myth Buster Quiz', url: '/resources/myth-buster/' },
              ],
            ],
          },
          {
            type: 'feature',
            title: 'A new way to see your atopic dermatitis',
            // description: 'A New Way To See Your AD',
            label: 'Watch the film',
            icon: 'triangleRight',
            link: '/understanding-ad/causes/',
            backgroundImage: FeatureImage,
            mobileBackgroundImage: FeatureImageMobile,
          },
        ],
        subLinks: [
          [
            { label: 'What is atopic dermatitis?', url: '/understanding-ad/what-is-ad/' },
            { label: 'What causes atopic dermatitis?', url: '/understanding-ad/causes/' },
            { label: 'Atopic dermatitis is a type of eczema', url: '/understanding-ad/ad-is-a-type-of-eczema/' },
            { label: 'Symptoms', url: '/understanding-ad/symptoms/' },
          ], [
            { label: 'Severity', url: '/understanding-ad/severity/' },
            { label: 'Common triggers', url: '/understanding-ad/triggers/' },
            { label: 'Allergies', url: '/understanding-ad/triggers/allergies/' },
            { label: 'Environmental triggers', url: '/understanding-ad/triggers/environment/' },
            { label: 'AD across skin tones', url: '/understanding-ad/triggers/ad-across-skin-tones/' },
          ],
        ],
      },
      {
        label: 'Managing AD',
        url: '/managing-ad/',
        header: {
          label: 'Managing AD',
          url: '/managing-ad/',
        },
        subLinks: [
          [
            { label: 'Finding control', url: '/managing-ad/control/' },
            { label: 'Diet', url: '/managing-ad/diet/' },
            { label: 'Sleep', url: '/managing-ad/sleep/' },
            { label: 'Materials and clothing', url: '/managing-ad/materials-and-clothing/' },
            { label: 'Seasons', url: '/managing-ad/seasons/' },
          ],
          [
            { label: 'Mental health', url: '/managing-ad/mental-health/' },
            { label: 'Stress', url: '/managing-ad/stress/' },
            { label: 'Treatments', url: '/managing-ad/treatment-guide/' },
            { label: 'Atopic dermatitis in children', url: '/managing-ad/ad-in-children/' },
            // { label: 'New Treatments FAQ', url: '/managing-ad/new-treatments/' },
            { label: 'Work', url: '/managing-ad/work/' },
          ],
        ],
        highlights: [
          // {
          //   type: 'list',
          //   title: 'Measure The Impact',
          //   links: [
          //     [
          //     ],
          //   ],
          // },
          {
            type: 'list',
            title: 'Appointment Support',
            links: [
              [
                { label: 'Talking to your dermatologist', url: '/resources/talking-to-your-dermatologist/' },
                // { label: 'Patient Support Journal', url: '/resources/support-journal/' },
                { label: 'Treatment Guide', url: '/managing-ad/treatment-guide/' },
                // { label: 'Caregiver Guide', url: '/resources/caregiver-guide/' },
                { label: 'Atopic Dermatitis Control Tool', url: '/resources/atopic-dermatitis-control-tool/' },
              ],
            ],
          },
        ],
      },
      {
        label: 'Initiatives',
        url: '/initiatives/',
        header: {
          label: 'Initiatives',
          url: '/initiatives/',
        },
        subLinks: [
          [
            { label: 'Agents of Change', url: '/initiatives/agents-of-change/' },
            { label: 'The AD GAP Survey', url: '/initiatives/AD-GAP/' },
            { label: 'World AD Day 5-Year Anniversary', url: '/initiatives/World-AD-Day-anniversary/' },
          ],
        ],
      },
      {
        label: 'Resources',
        url: '/resources/',
      },
    ]}
  />
);

export default Header;
