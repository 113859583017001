import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as Components from '@lxlabs/change-ad-components';
import Helmet from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import loadable from '@loadable/component';

// These components are _always_ shown/included on the page, there isn't really a reason to lazy
// load them. Also - it gives some weird jumping effect sometimes too...
import Header from '../components/header';
import Footer from '../components/footer';
import CookieBanner from '../components/cookieBanner';
import Seo from '../components/seo';

import '../fonts.css';

const AppCTA = loadable(() => import('../components/appCta'));
const Breadcrumbs = loadable(() => import('../components/breadcrumbs'));
const ContentCard = loadable(() => import('../components/contentCard'));
const ResourceCard = loadable(() => import('../components/resourceCard'));
const Graphic = loadable(() => import('../components/graphic'));
const Iframe = loadable(() => import('../components/iframe'));
const SymptomsColumns = loadable(() => import('../components/symptomsColumns'));
const KeyMessage = loadable(() => import('../components/keyMessage'));
const ParticipantCard = loadable(() => import('../components/participantCard'));
const GrantCard = loadable(() => import('../components/grantCard'));
const SideImage = loadable(() => import('../components/sideImage'));
const ManagingCard = loadable(() => import('../components/managingCard'));
const FlagList = loadable(() => import('../components/flagList'));
const FabricCard = loadable(() => import('../components/fabricCard'));
const TipCard = loadable(() => import('../components/tipCard'));
const VideoEmbed = loadable(() => import('../components/videoEmbed'));
const VideoList = loadable(() => import('../components/videoList'));
const VideoPopup = loadable(() => import('../components/videoPopup'));
const SeasonsColumns = loadable(() => import('../components/seasonsColumns'));
const DownloadCard = loadable(() => import('../components/downloadCard'));
const ExternalLink = loadable(() => import('../components/externalLink'));
const SectionCard = loadable(() => import('../components/sectionCard'));
const LegalSection = loadable(() => import('../components/legalSection'));
const SkinCard = loadable(() => import('../components/skinCard'));
const WorkCard = loadable(() => import('../components/workCard'));

const DefaultTemplate = ({ data, pageContext }) => {
  const PageBreadcrumbs = useCallback(
    (props) => <Breadcrumbs {...props} links={pageContext.breadcrumb.crumbs} />,
  );
  const PageSideImages = useCallback(
    (props) => <SideImage {...props} pageImages={pageContext.images} />,
  );

  const shortcodes = {
    ...Components,
    AppCTA,
    Breadcrumbs: PageBreadcrumbs,
    ResourceCard,
    Graphic,
    ContentCard,
    Iframe,
    SymptomsColumns,
    KeyMessage,
    ParticipantCard,
    GrantCard,
    SideImage: PageSideImages,
    FlagList,
    ManagingCard,
    FabricCard,
    TipCard,
    VideoEmbed,
    VideoList,
    VideoPopup,
    SeasonsColumns,
    DownloadCard,
    ExternalLink,
    SectionCard,
    LegalSection,
    SkinCard,
    WorkCard,
  };

  return (
    <Components.Theme>
      <Seo
        title={data.mdx?.frontmatter.title}
        description={data.mdx?.frontmatter.description}
        lang="en"
      />
      <Helmet>
        {process.env.GATSBY_STAGING && (
          <script
            type="text/javascript"
            src="https://www.bugherd.com/sidebarv2.js?apikey=xl44epdszxzyzbgyo8gr0g"
            async="true"
          />
        )}
      </Helmet>
      <CookieBanner />
      <Header />

      <MDXProvider components={{
        ...shortcodes,
      }}
      >
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>

      <Footer veevaCode={data.mdx?.frontmatter.veevaCode} />
    </Components.Theme>
  );
};

DefaultTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string,
      frontmatter: PropTypes.shape({
        veevaCode: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    breadcrumb: PropTypes.shape({
      crumbs: PropTypes.arrayOf(PropTypes.object),
    }),
    images: PropTypes.object,
  }).isRequired,
};

export default DefaultTemplate;

export const pageQuery = graphql`
  query Page($id: String!) {
    mdx(id: {eq: $id }) {
      id
      body
      frontmatter {
        veevaCode
        title
        description
      }
    }
  }
`;
